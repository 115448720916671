<script>
export default {
}
</script>

<template>
  <!-- MOB version  -->
  <div class="d-flex d-md-none flex-column col-12">
    <div class=" align-items-center text-container mt-5 col-12">
      <div class="font28 font-weight700  text-uppercase text-center mb-3 "> Добро пожаловать в<p class="font28 text-blue font-weight700 "> DENTA LAND!
        </p>
      </div>

      <p class="font18 text-ochre font-weight700  text-uppercase text-center">
        Если Вам не смогли оказать квалифицированную стоматологическую помощь в другой клинике, обращайтесь к нам,
        команда профессионалов Вам поможет!
        Мы относимся к каждому пациенту как к своему родственнику!
      </p>
    </div>
  </div>

  <div class="container mt-5 col-12">
    <div class="row flex-row align-items-center d-md-none">

      <div class="d-flex d-md-flex align-items-center flex-column mb-5 col-6">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleSeat.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> Профессиональные стоматологи </div>
      </div>

      <div class="d-flex align-items-center flex-column mb-5 col-6  ">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleStuff.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> Дружелюбный персонал </div>
      </div>

   <div class="d-flex align-items-center flex-column col-6 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleMedicine.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> Высококачественное оборудование </div>
      </div>

      <div class="d-flex align-items-center flex-column col-6 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleCoins.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> Оплата наличными и картой </div>
      </div>

      <div class="d-flex align-items-center flex-column col-6 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleHealth.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> На рынке с 2007 года </div>
      </div>

      <div class="d-flex align-items-center flex-column col-6 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleHB.png.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font16 d-flex text-center mt-4"> Скидка 10% в день рождения </div>
      </div> 
    </div>
  </div>



  <!-- PC version  -->
  <div class="d-flex flex-column col-12 d-md-flex d-none">
    <div class=" align-items-center text-container mt-3">
      <div class="font42 text-uppercase text-center mb-3 "> Добро пожаловать в<p class="font42 text-blue"> DENTA LAND!
        </p>
      </div>

      <p class="font26 text-ochre font-weight700  text-uppercase text-center">
        Если Вам не смогли оказать квалифицированную стоматологическую помощь в другой клинике, обращайтесь к нам,
        команда профессионалов Вам поможет!
        Мы относимся к каждому пациенту как к своему родственнику!
      </p>
    </div>
  </div>

  <div class="container mt-5 ">
    <div class="row d-md-flex d-none  justify-content-center ">
      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleSeat.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4 "> Профессиональные стоматологи </div>
      </div>

      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleStuff.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4"> Дружелюбный персонал </div>
      </div>

      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleMedicine.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4"> Высококачественное оборудование </div>
      </div>

      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleCoins.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4"> Оплата наличными и картой </div>
      </div>

      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleHealth.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4"> На рынке с 2007 года </div>
      </div>

      <div class="d-flex align-items-center flex-column col-3 mb-5">
        <div class="d-flex align-items-center justify-content-center position-relative">
          <img src="../assets/1circleHB.png.png" alt="Professionals" class="picture position-absolute">
          <div class="circlePic"> </div>
        </div>
        <div class="font-lato font-weight700 font18 d-flex text-center mt-4"> Скидка 10% в день рождения </div>
      </div>
    </div>
  </div>



</template>

<style>
.picture {
  height: 80px;
  width: 80px;
}

.circlePic {
  background: #E9E9E9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 163px;
  height: 163px;
}
</style>
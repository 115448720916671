<script>
export default {
}
</script>

<template>

 <div class="d-flex flex-column col-12 mt-4 mb-4">
    <div class=" font28 font-weight700 text-uppercase text-center mb-3 "> УСЛУГИ И ЦЕНЫ </div>
</div> 

     <!-- PC version  -->
<div class="container">
  <div class="row   flex-row col-12 d-none d-md-flex">   
    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2sit.png" alt="Consultation" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Осмотр, консультация</p>
    </div>

    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2toothache.png" alt="Toothache"  class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Прием с острой болью </p>
    </div>
     
    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2karies.png" alt="Karies" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Лечение кариеса, пульпита, периодонтита </p>
    </div>

    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2clean.png" alt="Whitening" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Отбеливание </p>
    </div>

    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2dental.png" alt="Parodontoz" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Лечение пародонтоза </p>
    </div>
    
    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2braces.png" alt="Braces" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Шинирование зубов </p>
    </div>

    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2allprotez.png" alt="All types of protez" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Все виды протезирования</p>
    </div>

    <div class="col-3 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2protez.png" alt="Protez"  class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Протезирование на имплантах </p>
    </div>
      <a href="/prices.jpg" download="prices.jpg" class="text-download text-yellow font-weight700 font20  text-uppercase text-center d-flex justify-content-center mt-3 mb-5"> Скачать прайс лист </a> 
    </div>
</div>

   <!-- Mobile version  -->
 
<div class="container">
  <div class="row flex-row align-items-center d-md-none col-12">   
    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2sit.png" alt="Consultation" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Осмотр, консультация</p>
    </div>

    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2toothache.png" alt="Toothache"  class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Прием с острой болью </p>
    </div>
     
    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2karies.png" alt="Karies" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Лечение кариеса, пульпита, периодонтита </p>
    </div>

    <div class="col-6 mb-4 d-flex flex-column align-self-baseline"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2clean.png" alt="Whitening" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Отбеливание </p>
    </div>

    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2dental.png" alt="Parodontoz" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Лечение пародонтоза </p>
    </div>
    
    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2braces.png" alt="Braces" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Шинирование зубов </p>
    </div>

    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2allprotez.png" alt="All types of protez" class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Все виды протезирования</p>
    </div>

    <div class="col-6 mb-4 d-flex flex-column"> 
      <div class="circle text-center justify-content-center align-self-center d-flex"> 
        <img src="../assets/2protez.png" alt="Protez"  class="picture justify-content-center align-self-center">  
      </div>   
      <p class="font-weight700 font18 text-uppercase  d-flex justify-content-center align-self-center align-items-center text-center mt-4"> Протезирование на имплантах </p>
    </div>
    <a href="/prices.jpg" download="prices.jpg" class="text-download text-yellow font-weight700 font20  text-uppercase text-center d-flex justify-content-center mt-3 mb-5"> Скачать прайс лист </a>
  </div> 
</div>
 

</template>

<style>
.picture {
  height: 70px;
  width: 70px;
}

.circle {
  border-radius: 50%;
  width: 163px;
  height: 163px;
  border: 3px solid #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.margin {
  margin-bottom: -60px; 
}

.text-download {
  color: #EDA230;
  text-decoration: none;
}
.text-download:hover {
    font-weight: 700;
    text-decoration: underline;
    color: brown;
}
</style>
<script>

import PageBlock from './components/PageBlock.vue'
import HeaderEl from './components/HeaderEl.vue'
import NavBar from './components/NavBar.vue'
import PageGreeting from './components/PageGreeting.vue'
import PageBenefits from './components/PageBenefits.vue'
import PagePrice from './components/PagePrice.vue'
import GradientBlock from './components/GradientBlock.vue'
import PageContact from './components/PageContact.vue'

export default {
  components: { PageBlock, HeaderEl, NavBar, PageGreeting, PageBenefits, PagePrice, GradientBlock, PageContact },
  methods: {
    ScrollUp() {
      let mybutton = document.getElementById("upBtn");

      // When the user scrolls down 20px from the top of the document, show the button
      window.onscroll = function () { scrollFunction() };

      function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          mybutton.style.display = "block";
        } else {
          mybutton.style.display = "none";
        }
      }
      // When the user clicks on the button, scroll to the top of the document
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
}

</script>
<template>

  <div class="container">
    <PageBlock bgcolor="#E4E4E4">
      <HeaderEl>
      </HeaderEl>
    </PageBlock>
  </div>

  <div class="container-fluid">
    <PageBlock bgcolor="#378DDD">
      <NavBar> </NavBar>
      </PageBlock>
  </div>

  <div class="container-fluid" id="google">
    <PageBlock bgcolor="#C2DEF4">
      <PageGreeting>
      </PageGreeting>
    </PageBlock>
  </div>

  <div class="container">
    <PageBlock>
      <PageBenefits>
      </PageBenefits>
    </PageBlock>
  </div>

  <div class="container" >
    <PageBlock id="block2">
      <PagePrice >
      </PagePrice>
    </PageBlock>
  </div>

  <div class="container-fluid" style="background-image: linear-gradient(#6F96FA, #11E3BE)">
    <PageBlock>
      <GradientBlock> </GradientBlock>
    </PageBlock>
  </div>

  <div class="container" id="block3">
    <PageBlock>
      <PageContact> </PageContact>
    </PageBlock>
  </div>

  <div class="container-fluid">
    <PageBlock bgcolor="#378DDD">
      <NavBar></NavBar>
    </PageBlock>
  </div>

  <button @click="ScrollUp()" class="d-flex" id="upBtn" title="Go to top">
    <svg xmlns="http://www.w3.org/2000/svg" style="transform: rotate(270deg);" width="20" height="20"
      viewBox="0 0 24 24">
      <path fill="#ffffff" d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  </button>
</template>

<style>
body {
  background-color: #E4E4E4;
  font-family: 'Source Sans 3', sans-serif;
  color: black;
  font-size: 20px;
  font-weight: 400;
}

#upBtn {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 99;
  cursor: pointer;
  padding: 15px;
  border-radius: 25px;
  font-size: 18px;
  border: none;
  background-color: rgb(122, 122, 122);
}

#upBtn:hover {
  background-color: #9E6F29;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.text-ochre {
  color: #9E6F29;
}

.text-yellow {
  color: #EDA230;
}

.text-blue {
  color: #378DDD;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font26 {
  font-size: 26px;
}

.font28 {
  font-size: 28px;
}


.font35 {
  font-weight: 700;
  font-size: 35px;
}

.font42 {
  font-weight: 700;
  font-size: 42px;
}

.font50 {
  font-weight: 700;
  font-size: 50px;
}

.icons36 {
  width: 36px;
  height: 36px;
}

.font-weight400 {
  font-weight: 400;
}

.font-weight700 {
  font-weight: 700;
}

a {
  text-decoration: none;
}
</style>



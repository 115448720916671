<script>
export default {
}

</script>

<template>

    <!-- PC version -->
    <div class="container">
        <div class="row d-none d-md-flex justify-content-center col-12"> 
            <img src="../assets/people.png" alt="Couple" class="image mt-3 d-flex col-6" />

            <div class="d-flex col-3 flex-column align-self-center justify-content-center align-items-center mb-5 d-md-flex d-none">
                <p class="font-weight700 font26 text-uppercase header-text text-center">
                    Нам можно доверять! <br>
                    Стоматология без боли! <br>
                    Белоснежная улыбка за один визит!
                </p>

                <div class="d-flex flex-column align-self-center mb-5">
                    <div class="align-self-center mb-4">
                        <button class="btn webBtn d-flex align-items-center justify-content-center">
                            <img src="../assets/whatsapp.png" alt="Whatsapp" class="icons36" />
                            <a href="https://wa.me/+77779223138"
                                class="font20 text-uppercase mx-3 font-weight700 selected-text">
                                Записаться через Whatsapp
                            </a>
                        </button>
                    </div>

                    <div class="align-self-center">
                        <button class="btn webBtn d-flex align-items-center justify-content-center">
                            <img src="../assets/instagram.png" alt="Whatsapp" class="icons36" />
                            <a a href="https://www.instagram.com/denta_land/"
                                class="font20 text-uppercase mx-3 font-weight700 inst-text selected-textt">
                                Записаться через Instagram
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
 

    <!-- Mob version -->
 <div class="container d-md-flex d-md-none">
        <div class="row d-flex justify-content-center">
            <img src="../assets/people.png" alt="Couple" class="image-mob text-center d-flex justify-content-center" />
            <div class="d-flex d-md-flex d-md-none d-flex flex-column">
                <p class="font-weight700 font20 text-uppercase header-text-mobile text-center">
                    Нам можно доверять! <br>
                    Стоматология без боли! <br>
                    Белоснежная улыбка за один визит!
                </p>

                <div class="d-md-flex d-flex  flex-column align-self-center">
                    <div class="d-flex flex-column align-self-center mb-5">
                        <div class="align-self-center mb-4">
                            <button class="btn webBtn-mob d-flex align-items-center justify-content-center">
                                <img src="../assets/whatsapp.png" alt="Whatsapp" class="icons36" />
                                <a href="https://wa.me/+77779223138"
                                    class="font16 text-uppercase mx-3 font-weight700 selected-text">
                                    Записаться через Whatsapp
                                </a>
                            </button>
                        </div>

                        <div class="align-self-center">
                            <button class="btn webBtn-mob d-flex align-items-center justify-content-center">
                                <img src="../assets/instagram.png" alt="Whatsapp" class="icons36" />
                                <a a href="https://www.instagram.com/denta_land/"
                                    class="font16 text-uppercase mx-3 font-weight700 inst-text selected-textt">
                                    Записаться через Instagram
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<style>
.mainContainer {
    display: flex;
    width: 1320px;
    justify-content: center;
    margin-left: 250px;
}

.image {
    width: 650px;
    height: 630px;
}

.image-mob {
    width: 460px;
    height: 430px
}

.webBtn {
    width: 418px;
    height: 48px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 35px;
    background-color: #FFFFFF;
}

.webBtn-mob {
    width: 370px;
    height: 40px;
       box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 35px;
    background-color: #FFFFFF;
}

.btn:hover {
    background-color: #378DDD;
    outline: #FFFFFF;
}

.btn:focus {
    border: 0px;
}

.header-text {
    margin-top: 160px;
    margin-bottom: 150px;
}

.header-text-mobile {
    margin-top: 40px;
    margin-bottom: 40px;
}


.inst-text {
    background: -webkit-linear-gradient(rgb(253, 229, 18), rgb(185, 11, 135));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.selected-text:hover {
    color: #FFFFFF;
}
</style>


<script>
export default {
}
</script>

<template>
    <div class="navbar d-none d-md-flex justify-content-center align-items-center font_lato text-white">
        <a href="#google" class="text_nav text-decoration-none">
            О НАС
        </a>
        <a href="#block2" class="text_nav">
            УСЛУГИ
        </a>
        <a href="#block3" class="text_nav">
            ГДЕ НАС НАЙТИ
        </a>
    </div>

</template>

<style scoped>
.navbar {
    height: 50px;
}

.text_nav {
    color: white;
    margin-right: 15px;
    margin-left: 15px;
    text-decoration: none;
}

.text_nav:hover {
    font-weight: 700;
    text-decoration: underline;
    color: #EDA230;
}
</style>







<script>
export default {
    props: ['bgcolor']
}
</script>

<template>
    <div class="d-flex flex-column col-12 mt-4 mb-4">
        <div class=" font28 font-weight700 text-uppercase text-center mb-3 "> КОНТАКТЫ </div>
    </div>

      <!-- PC version  -->
   <div class="d-flex flex-column ">
        <div class="row  d-none d-md-flex">

            <div class="col-8 mb-4 d-flex">
                <img src="../assets/kabinet.jpg" alt="Consultation"
                    class="img justify-content-center align-self-center">
            </div>

            <div class="col-4 mb-4 d-flex ">
                 <video width="340" height="450" controls="controls" poster="../assets/video-photo.jpg">
                    <source src="../assets/video.mp4" type="video/mp4">  
                </video>   
            </div>

            <div class="col-8 mb-4 d-flex">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d482.7591369198407!2d69.14972012077176!3d54.86074488782282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43b23a308ae199bb%3A0x96d7edcdd581f6f0!2z0YPQuy4g0JHRg9C60LXRgtC-0LLQsCA3OSwg0J_QtdGC0YDQvtC_0LDQstC70L7QstGB0LogMTUwMDAwLCDQmtCw0LfQsNGF0YHRgtCw0L0!5e0!3m2!1sru!2s!4v1669452722617!5m2!1sru!2s"
                    width="680px" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"> </iframe>
            </div>

            <div class="col-4 mb-4 d-flex flex-column">
                <div class="d-flex flex-row">
                    <img src="../assets/pin.png" alt="Consultation" class="icons36">
                    <p class="mx-3"> Казахстан, Петропавловск, ул. Букетова, д. 79 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/phone.png" alt="Consultation" class="icons36">
                    <p class="mx-3"> 8 (7152) 33-06-93 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/whatsapp.png" alt="Consultation" class="icons36">
                    <p class="mx-3"> 8-777-922-31-38 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/email.png" alt="Consultation" class="icons36">
                    <p class="mx-3"> denta-land@mail.ru </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/instagram.png" alt="Consultation" class="icons36">
                    <p class="mx-3"> @denta_land </p>
                </div>
            </div>
        </div>
    </div> 



      <!-- Mobile version  -->
    <div class="d-flex flex-column justify-content-center ">
        <div class="column d-md-none justify-content-center">

            <div class="col-8 mb-4 d-flex">
                <img src="../assets/kabinet.jpg" alt="Consultation"
                    class="imgMobile justify-content-center align-self-center">
            </div>

            <div class="col-4 d-flex">
                 <video width="400" height="640" controls="controls"  poster="../assets/video-photo.jpg" >
                    <source src="../assets/video.mp4" type="video/mp4">  
                </video>   
            </div>

            <div class="col-8 mb-4 d-flex mt-4">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d482.7591369198407!2d69.14972012077176!3d54.86074488782282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43b23a308ae199bb%3A0x96d7edcdd581f6f0!2z0YPQuy4g0JHRg9C60LXRgtC-0LLQsCA3OSwg0J_QtdGC0YDQvtC_0LDQstC70L7QstGB0LogMTUwMDAwLCDQmtCw0LfQsNGF0YHRgtCw0L0!5e0!3m2!1sru!2s!4v1669452722617!5m2!1sru!2s"
                    width="620px" height="500" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"> </iframe>
            </div>

            <div class=" mb-4 d-flex flex-column">
                <div class="d-flex flex-row">
                    <img src="../assets/pin.png" alt="Consultation" class="icons36">
                    <p class="mx-3 font18"> Казахстан, Петропавловск, ул. Букетова, д. 79 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/phone.png" alt="Consultation" class="icons36">
                    <p class="mx-3 font18"> 8 (7152) 33-06-93 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/whatsapp.png" alt="Consultation" class="icons36">
                    <p class="mx-3 font18"> 8-777-922-31-38 </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/email.png" alt="Consultation" class="icons36">
                    <p class="mx-3 font18"> denta-land@mail.ru </p>
                </div>

                <div class="d-flex flex-row">
                    <img src="../assets/instagram.png" alt="Consultation" class="icons36">
                    <p class="mx-3 font18"> @denta_land </p>
                </div>
            </div>
        </div>
    </div>

</template>


<style scoped>
.img {
    width: 680px;
    height: 450px;
}

.imgMobile {
    width: 360px;
    height: 280px;
}
.video {
    width: 340px;
    height: 450px;
}

.icons36 {
    width: 36px;
    height: 36px;
}

 
</style>
<script>
export default { 
}
</script>
    
<template>
    <p class="font-weight700 font18 font-lato text-uppercase text-white text-center mb-4 mt-4">
        Мы предоставляем больше услуг. <br>
        Если у вас другая проблема, позвоните нам, и мы постараемся вам помочь!
    </p>
</template>

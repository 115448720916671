<script>
export default {
}
</script>

<template>
    <!-- Mobile version  -->
    <div class="col-12 d-flex d-md-none align-items-center text-center">
        <img src="../assets/dentaland_logo.png" alt="Denta Land" class="icons135" />
        <div class="font35 text-blue">DENTA LAND</div>
    </div>

    <div class="col-12 d-md-none d-flex  mt-3 mb-3 text-center">
        <img src="../assets/pin.png" alt="pin" class="icons36 me-3">
        <div>
            <div class="font16"> Казахстан, Петропавловск, ул.Букетова, д.79 </div>
            <div class="text-ochre font16"> 10.00-18.00, cб 10.00-16.00, вс - выходной </div>
        </div>
    </div>

    <div class="col-12 d-md-none d-flex mt-3 mb-3 text-center align-items-center text-center">
        <div class="flex-fill ">
            <div>
                <img src="../assets/phone.png" alt="Phone" class="icons36 mx-3">
                <a class="font16" href="tel:+7(7152) 33-06-93"> 8(7152) 33-06-93 </a>
            </div>

            <div>
                <img src="../assets/whatsapp.png" alt="Whatsapp" class="icons36 mx-3">
                <a  class="font16"  href="tel:+7-777-922-31-38"> 8-777-922-31-38 </a>
            </div>
        </div>
    </div>

    <!--     PC version -->
    <div class="d-none col-md-5 d-md-flex align-items-center">
        <img src="../assets/dentaland_logo.png" alt="Denta Land" class="icons135" />
        <div class=" text-blue font50" >DENTA LAND</div>
    </div>

    <div class="d-none col-md-5 d-md-flex align-items-center">
        <img src="../assets/pin.png" alt="pin" class="icons36 me-3">
        <div>
            <div> Казахстан, Петропавловск, ул. Букетова, д. 79 </div>
            <div class="text-ochre"> 10.00-18.00, cб 10.00-16.00, вс - выходной </div>
        </div>
    </div>

    <div class="d-none col-md-2 d-md-flex align-items-center">
        <div class="flex-fill text-end">
            <div>
                <img src="../assets/phone.png" alt="Phone" class="icons36 mx-2">
                <a href="tel:+7(7152) 33-06-93"> 8(7152) 33-06-93 </a>
            </div>

            <div>
                <img src="../assets/whatsapp.png" alt="Whatsapp" class="icons36 mx-2">
                <a href="https://wa.me/+77779223138"> 8-777-922-31-38 </a>
            </div>
        </div>
    </div>

</template>

<style scoped>
.icons135 {
    width: 135px;
    height: 135px;
}
</style>
<script>
export default {
    props: ['bgcolor']
}
</script>
    
<template>
    <div class="row" :style="{ 'background-color': bgcolor }">
        <slot>
        </slot>
    </div>
</template>
